// 3rd-party modules
import { useSelector } from 'react-redux';

// project modules
import { setCache } from '../stores/cacheStore';
import { useAppDispatch } from './storeHooks';
import { RootState } from '../stores';

export function useCacheHook() {
  const cacheStore = useSelector((state: RootState) => state.cache);
  const dispatch = useAppDispatch();
  const setCachedData = (key: string, data: any) => dispatch(setCache({ key, data }));

  return [cacheStore.cahcedData, setCachedData];
}
