// 3rd-party modules
import moment from "moment-timezone";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";

// project modules
import Button from "../../shared/button";
import DataListView from "../../shared/list/dataListView";
import Loader from "../../shared/loader";
import { apiCall } from "../../../helpers/apiHelper";
import { formatMoney } from "../../../helpers/objectHelper";
import { ListViewType } from "../../shared/list/useListHook";

// apis
import * as AccountBillingApi from '../../../apis/accountBillingApi';

// models
import { Account } from "../../../models/account";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";
import { AccountBilling } from "../../../models/accountBilling";

// defines
import { CONSTANTS } from "../../../helpers/defines";

type Props = {
  account?: Account;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountBillings({ account, refresh, onCancel, onSave }: Props) {
  // const [dataSource, setDataSource] = useState<AccountBilling[]>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  // useEffect(() => {
  //   getPageAsync();
  // }, []);

  useEffect(() => {
    if (reload) {
      // getPageAsync();
      setReload(false);
    }
  }, [reload]);

  // const getPageAsync = async () => {
  //   setDataSource(await getPage())
  // }

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);
    const response = await apiCall(AccountBillingApi.getAccountBillings(account?.accountId!, request, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? AccountBilling.toArrayOfClass(response.data?.value || []) : [];
  };

  const onDownloadPDF = (record: AccountBilling) => {
    const newWindow = window.open(record.invoicePdf, '_blank');

    newWindow?.focus();
  };

  const BlockItem: React.FC<{ data: any }> = ({ data }) => {
    return (
      <group data-direction="column" data-border="" data-radius="10" data-contain="">
        <group
          data-space="30"
          data-gap="5"
          data-wrap="no"
          data-direction="column"
        >
          <group>
            <text data-weight="700" data-color="main">Billing</text>
          </group>

          <text data-wrap="wrap">
            Payment Date: {moment(data.paymentDate).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}
          </text>
          <text data-wrap="wrap" data-text-size="medium" data-weight="700">
            {formatMoney(Number(data.amountPaid) / 100, 2)} {data.currency.toUpperCase()}
          </text>

          <text data-wrap="wrap">
            Kibosh Device id.: {data.kiboshDeviceReference}
          </text>
          <text>
            Status: {data.status}
          </text>
        </group>
        <group data-gap="5" data-space="10" data-position="bottom" data-align="center" data-border="" data-wrap="no">
          <Tooltip title="Download PDF">
            <Button
              disabled={data.InvoicePdf || null}
              micro
              icon="download"
              onClick={() => onDownloadPDF(data)}/>
          </Tooltip>
        </group>
      </group>
    )
  };

  return (
    <>
      {loading && <Loader />}
      <view
        data-scroll=""
        data-space="40"
        data-gap="30">
        <group data-gap="10" data-direction="column">
          <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">Billings</text>
          <text data-wrap="wrap" data-length="300" data-line="1.5" data-light="">Here you can manage all your billings.</text>
        </group>
        <group data-width="auto" data-position="left">
          <Tooltip title="Refresh" arrow={false}>
            <group data-width="auto">
              <Button
                secondary
                data-length="50"
                data-height="50"
                data-elevation="6"
                rounded
                icon="refresh"
                //text="New"
                onClick={() => setReload(true)}
              />
            </group>
          </Tooltip>
        </group>
        <DataListView
          dataPagination={false}
          // dataSource={dataSource}
          getPageAsync={getPage}
          keyField="paymentId"
          itemComponent={BlockItem}
          lazyLoadPages={true}
          loading={loading}
          reload={reload}
          renderContainer={false}
          totalRecords={totalRecords}
          view={ListViewType.Block}
        />
      </view>
    </>
  );
}
