// 3rd-party modules
import moment from 'moment-timezone';
import { message } from "antd";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

// project modules
// import AttachmentsPicker from "../../../attachments/AttachmentsPicker";
import Button from "../../../shared/button";
import ConfirmationPopup from "../../../shared/popup/confirmationPopup";
import Input, { InputType } from "../../../shared/inputs/input";
import Loader from "../../../shared/loader";
import Popup from "../../../shared/popup/popup";
import yup from "../../../../plugins/yup";
import { apiCall } from "../../../../helpers/apiHelper";
import { b64toBlob } from '../../../../helpers/objectHelper';
import { RootState } from "../../../../stores";
import { Text } from '../../../shared/text';

// apis
import * as AttachmentApi from '../../../../apis/attachmentApi';
import * as CommentApi from '../../../../apis/commentApi';
import * as SupportTicketApi from '../../../../apis/supportTicketApi';

// models
import { ApiResponse } from "../../../../models/response";
import { Attachment } from "../../../../models/attachment";
import { Comment } from "../../../../models/comment";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../../models/dataSourceRequest";
import { SupportTicket } from "../../../../models/supportTicket";
import { User } from "../../../../models/auth";

type CommentByDate = {
  date: string;
  items: Comment[];
}

type Props = {
  closeOnSave?: boolean;
  open: boolean;
  supportTicket: SupportTicket;
  modalTitle?: string;
  onClose?: () => void;
  onSave?: () => void;
};

const SUPPORT_TICKET_ENTITY_CODE = "support-ticket";

export default function CommentsModal({ closeOnSave = false, modalTitle = "", open, onClose, onSave, supportTicket }: Props) {
  const schema = yup.object().shape({
    message: yup.string().label("Message").max(500).required(),
  });
  const { control, handleSubmit, reset, setValue } = useForm<Comment | any>({
    resolver: yupResolver(schema),
  });
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [comments, setComments] = useState<Comment[]>([]);
  const [datasource, setDatasource] = useState<CommentByDate[]>([]);
  const [isCloseTicketConfirmationPopupOpen, setIsCloseTicketConfirmationPopupOpen] = useState(false);
  const [isReopenTicketConfirmationPopupOpen, setIsReopenTicketConfirmationPopupOpen] = useState(false);
  const [loadingAttachments, setLoadingAttachments] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state: RootState) => state.user.currentUser) as User;
  const abortController = new AbortController();

  useEffect(() => {
    if (open) {
      getAttachmentsAsync();
      getCommentsAsync();
    }
  }, [open]);

  useEffect(() => {
    let data: CommentByDate[] = [];

    if (comments?.length) {
      comments.forEach(item => {
        const date = moment(item.createdAt).format("DD/MM/YYYY");
        const parentIndex = data.findIndex(x => x.date === date);

        if (parentIndex > -1) {
          data[parentIndex].items.push(item);
        } else {
          data.push({ date, items: [item]});
        }
      });
    }
    setDatasource(data);
  }, [comments]);

  const getAttachmentsAsync = async () => {
    setAttachments(await getAttachments(abortController.signal));
  }

  const getAttachments = async (abortSignal?: AbortSignal) => {
    setLoadingAttachments(true);
    const response = await apiCall(AttachmentApi.getAttachments(SUPPORT_TICKET_ENTITY_CODE, supportTicket.supportTicketId!, abortSignal));
    setLoadingAttachments(false);

    return response.success ? Attachment.toArrayOfClass(response.data?.value || []) : [];
  };
  const getCommentsAsync = async () => {
    setComments(await getComments([], [], new PaginationDescriptor(), abortController.signal));
  }

  const getComments = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoadingComments(true);
    const response = await apiCall(CommentApi.getComments(SUPPORT_TICKET_ENTITY_CODE, supportTicket.supportTicketId!, request, abortSignal));
    setLoadingComments(false);

    return response.success ? Comment.toArrayOfClass(response.data?.value || []) : [];
  };

  const markReadComments = async () => {
    setLoadingComments(true);
    await apiCall(CommentApi.markReadComments(SUPPORT_TICKET_ENTITY_CODE, supportTicket.supportTicketId!));
    setLoadingComments(false);
  };

  const onCancel = () => {
    abortController.abort();
    markReadComments();

    if (onClose) onClose();
  };

  const onCommentCancel = () => {
    reset();
    setValue("message", "");
    onCancel();
  };

  const onAttachmentItemClick = async (data: Attachment) => {
    setLoadingAttachments(true);
    const response = await apiCall(AttachmentApi.getAttachmentById(
      data.attachmentId!,
      abortController.signal
    ));
    setLoadingAttachments(false);

    if (response.success) {
      const url = window.URL.createObjectURL(b64toBlob(response.data?.value?.fileContents));
      const a = document.createElement("a");

      a.href = url;
      a.download = data.originalFileName!;

      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      message.error(response.error?.value);
    }
  };

  const onSubmit: SubmitHandler<Comment> = async (formData: Comment) => {
    formData.entityCode = SUPPORT_TICKET_ENTITY_CODE;
    formData.entityId = supportTicket.supportTicketId;
    formData.from = user ? `${user.firstName} ${user.lastName}` : "Admin"; //TODO: Remove - API sets this
    formData.type = "client"; //TODO: Remove - API sets this

    setLoading(true);
    let response: ApiResponse = await apiCall(CommentApi.insertComment(formData, abortController.signal));

    if (response.success) {
      message.success(`Comment added successfully.`);
      getCommentsAsync();

      onCommentCancel();
    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const onCloseTicketClick = () => {
    setIsCloseTicketConfirmationPopupOpen(true);
  };

  const onReopenTicketClick = () => {
    setIsReopenTicketConfirmationPopupOpen(true);
  };

  const handleTicketStatusChangeRequest = async (status: string) => {
    const formData = {
      ...supportTicket,
      status
    };
    setLoading(true);
    const response = await apiCall(SupportTicketApi.updateSupportTicket(formData, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Support Ticket# ${supportTicket.supportTicketId} ${status} successfully.`);
      setIsCloseTicketConfirmationPopupOpen(false);
      setIsReopenTicketConfirmationPopupOpen(false);

      if (onSave) onSave();

      if (closeOnSave) {
        open = false;

        onCancel();
      }
    }
  };

  const renderTicketDetailsSection = () => {
    return (
      <>
        <group data-gap="10" data-space="10">
          {supportTicket.status === "closed" && (
            <Button
              data-position="right"
              primary
              data-length="forcefit"
              onClick={onReopenTicketClick}
            >
              Re-open this ticket
            </Button>
          )}
          {supportTicket.status !== "closed" && (
            <Button
              data-position="right"
              primary
              data-length="forcefit"
              onClick={onCloseTicketClick}
            >
              Close this ticket
            </Button>
          )}
        </group>
        <group data-gap="10" data-align="center">
          <group data-width="auto" data-gap="10">
            <text data-light="">Ticket #:</text>
            <Text data-weight="600">{supportTicket.supportTicketId}</Text>
          </group>
          <separator vertical="" data-height="20"></separator>
          <group data-width="auto" data-align="center" data-gap="5">
            <text data-light="">Owner: </text>
            <text>{supportTicket.contactName}</text>
          </group>
          <separator vertical="" data-height="20"></separator>
          <group data-width="auto" data-align="center" data-gap="5">
            <text data-light="">Priority:</text>
            <badge
              data-color={
                supportTicket.priority === "low"
                  ? "green"
                  : supportTicket.priority === "medium"
                    ? "orange"
                    : supportTicket.priority === "high"
                      ? "red"
                      : ""
              }
              data-outline=""
            >
              {supportTicket.priority}
            </badge>
          </group>
          <separator vertical="" data-height="20"></separator>
          <group data-width="auto" data-align="center" data-gap="5">
            <text data-light="">Assignee: </text>
            <text>{supportTicket.assigneeFullName}</text>
          </group>
          <separator vertical="" data-height="20"></separator>
          <group data-width="auto" data-gap="5">
            <text data-light="">Category:</text>
            <Text>{supportTicket.ticketCategory}</Text>
          </group>
        </group>
        <separator horizontal=""></separator>
        <group data-direction="column" data-gap="10">
          <text data-weight="600">Description</text>
          <text data-light="" data-wrap="wrap">
            {supportTicket.detail}
          </text>
        </group>

      </>
    );
  }

  const renderAttachmentsSection = () => {
    return (
      <>
        {loadingAttachments && <Loader />}
        {!!attachments.length && (
          <>
            <group
              //data-direction="column"
              data-gap="10"
              data-space-vertical="10"
            //data-column-template="4"
            //data-type="column"
            >
              {attachments.map((item) => (

                <group
                  key={item.attachmentId}
                  data-border=""
                  data-length="autofill"
                  data-fit="1"
                  data-radius="5"
                  data-align="center"
                  data-wrap="no"
                  data-cursor="pointer"
                  onClick={() => onAttachmentItemClick(item)}
                >
                  <icon data-space="5">attach_file</icon>
                  <separator vertical="dotted" data-height="30"></separator>
                  <text data-space="5" data-ellipsis="" data-light="">
                    {item.originalFileName}
                  </text>
                </group>

              ))}
            </group>

          </>
        )}
      </>
    );
  }

  const renderNewCommentSection = () => {
    return (
      <>
        <group
          data-space="15"
          data-align="center"
          data-gap="10"
          data-elevation="1"
          data-sticky="bottom"
          data-backdrop=""

        >
          <Input
            disabled={supportTicket.status === "closed"}
            control={control}
            name="message"
            type={InputType.TextArea}
            dataLength="auto"
            resize=""
            placeholder="Comment"
            aria-invalid="false"
            data-height="100"
          />
          <group data-gap="10">
            <Button
            disabled={supportTicket.status === "closed"}
            material
            primary
            onClick={handleSubmit(onSubmit)}
            data-length="forcefit"
          >
            <text>Send The Comment</text>
          </Button>
          <Button
            disabled={supportTicket.status === "closed"}
            material
            outline
            onClick={onCommentCancel}
            data-length="forcefit"
          >
            <text>Cancel</text>
          </Button>


          {supportTicket.status === "closed" && (
            <Button
              data-position="right"
              accent
              data-length="forcefit"
              onClick={onReopenTicketClick}
            >
              Re-open this ticket
            </Button>
          )}
          {supportTicket.status !== "closed" && (
            <Button
              data-position="right"
              accent
              data-length="forcefit"
              onClick={onCloseTicketClick}
            >
              Close this ticket
            </Button>
          )}
          </group>


        </group>
      </>
    );
  }

  return (
    <Popup
      adaptive
      title={ modalTitle || `Support Ticket: ${supportTicket.subject}`}
      onCancel={onCancel}
      onClose={onCancel}
      noCommandbar={true}
      fixSize="medium"
    >
      {loading && <Loader />}
      <view>
        <view
          data-scroll=""
          data-gap="30"
          data-space="20"
        >
          <group data-gap="10" data-align="center">
            {renderTicketDetailsSection()}
            <separator horizontal=""></separator>
            {renderAttachmentsSection()}
          </group>
          <group
            data-direction="column"
            data-gap="10"
            data-wrap="no"
            //data-space="20"
          >
            <group
            //data-border="top"
            //data-space-vertical="10"
            >
              <text data-weight="600">Comments</text>
            </group>
            {loadingComments && <Loader />}
            {datasource.map((x) => (
              <>
                <group data-space="5">
                  <text data-weight="600" data-light="">
                    {x.date}
                  </text>
                </group>
                <group data-direction="column" data-gap="10" data-wrap="no">
                  {x.items.map((item) => (
                    <>
                      <group
                        className={!item.readAt && "unreadComment"}
                        data-radius="10"
                        data-background={
                          item.type === "admin" ? "main-lighter" : "main-light"
                        }
                        data-space="10"
                        data-gap="10"
                        data-wrap="no"
                        data-border=""
                        data-align={item.type === "admin" ? "start" : ""}
                        data-width="90"
                        data-position={item.type === "admin" ? "left" : "right"}
                        data-radius-top-left={item.type === "admin" ? "0" : null}
                        data-radius-bottom-right={
                          item.type === "admin" ? null : "0"
                        }
                      >
                        <group
                          data-width="auto"
                          data-direction="column"
                          data-gap="10"
                        >
                          <group data-direction="column">
                            <text data-weight="600">{item.from}</text>
                            <text data-light="">{item.type}</text>
                          </group>
                          <group>
                            <text data-wrap="wrap">{item.message}</text>
                          </group>
                        </group>
                        <group
                          data-width="auto"
                          data-position="right"
                          data-justify="center"
                        >
                          <text>{moment(item.createdAt).format("HH:mm")}</text>
                        </group>
                      </group>
                    </>
                  ))}
                </group>
              </>
            ))}
          </group>
        </view>
        {renderNewCommentSection()}
      </view>

      {isCloseTicketConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Close This Ticket"
          positiveCallback={() => handleTicketStatusChangeRequest("closed")}
          negativeCallback={() => {
            setIsCloseTicketConfirmationPopupOpen(false);
          }}
        />
      )}
      {isReopenTicketConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Re-open This Ticket"
          positiveCallback={() => handleTicketStatusChangeRequest("re-open")}
          negativeCallback={() => {
            setIsReopenTicketConfirmationPopupOpen(false);
          }}
        />
      )}
    </Popup>
  );
}
