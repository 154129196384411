// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

export const uploadFile = async (data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Attachment/UploadFile`, 'post', data, {
    headers: {
      'Content-Type': 'multipart/form-data', // You can override here if needed
      // Add other headers if necessary
    }
  }, abortSignal);
};

export const getAttachments = async (entityCode: string, entityId: number, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Attachment/EntityType/${entityCode}/Entity/${entityId}/List`, 'get', null, null, abortSignal);
};

export const getAttachmentById = async (attachmentId: number, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Attachment/DownloadFile/${attachmentId}`, 'get', null, null, abortSignal);
};

export const getAttachmentByName = async (fileName: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Attachment/DownloadFile`, 'get', fileName, { responseType: 'blob' }, abortSignal);
};

export const deleteAttachment = async (attachmentId: number, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Attachment/DownloadFile/${attachmentId}`, 'delete', null, null, abortSignal);
};
